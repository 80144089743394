.container {
    padding: 40px 0;
    width: 90%;
    margin: 0 auto;
    min-height: 80vh;
    background-color: #fff;
    max-width: 800px;

    .header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 32px;

        h1 {
            font-size: 24px;
            font-weight: 800;
            color: #333;
            line-height: 1.4;
            display: flex;
            align-items: center;
            gap: 8px;

            span {
                font-weight: 400;
                color: #666;
                font-size: 14px;
                display: block;
                margin-top: 8px;

                @media (min-width: 768px) {
                    font-size: 16px;
                }
            }

            @media (min-width: 768px) {
                font-size: 30px;
            }
        }
    }

    .sectionTitle {
        font-size: 18px;
        font-weight: 600;
        color: #333;
        margin: 24px 0 16px;
        padding-bottom: 8px;
        border-bottom: 1px solid #eee;
    }

    .staffList {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .staffCard {
        background: #fff;
        border-radius: 24px;
        padding: 24px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.1);
        border: 1px solid #f0f0f0;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: 639px) {
            padding: 16px;
            gap: 12px;
            border-radius: 16px;
        }

        .staffHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .userInfo {
                display: flex;
                align-items: center;
                gap: 12px;

                .userIcon {
                    color: #99419D;
                    font-size: 24px;

                    @media (max-width: 639px) {
                        font-size: 20px;
                    }
                }

                h3 {
                    font-size: 20px;
                    font-weight: 500;
                    color: #333;

                    @media (max-width: 639px) {
                        font-size: 16px;
                    }
                }
            }
        }

        .infoContainer {
            display: grid;
            gap: 16px;
            
            .infoRow {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                gap: 0px;

                .label {
                    color: #6B7280;
                    font-size: 14px;
                    font-weight: 500;

                    @media (max-width: 639px) {
                        font-size: 12px;
                    }
                }

                .value {
                    color: #111827;
                    font-size: 16px;

                    @media (max-width: 639px) {
                        font-size: 14px;
                    }
                }

                .passwordField {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    background: #F9FAFB;
                    border-radius: 12px;
                    padding: 8px 16px;

                    @media (max-width: 639px) {
                        padding: 6px 12px;
                        border-radius: 8px;
                    }

                    input {
                        background: none;
                        border: none;
                        font-size: 16px;
                        color: #111827;
                        width: 100%;
                        
                        @media (max-width: 639px) {
                            font-size: 14px;
                        }

                        &:focus {
                            outline: none;
                        }
                    }

                    button {
                        background: none;
                        border: none;
                        color: #6B7280;
                        cursor: pointer;
                        padding: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &:hover {
                            color: #374151;
                        }
                    }
                }

                &:first-child {
                    max-width: 100px;
                }
            }
        }
    }

    .noStaff {
        text-align: center;
        color: #666;
        padding: 32px;
        background: #f8f8f8;
        border-radius: 12px;

        @media (max-width: 639px) {
            padding: 24px;
            font-size: 14px;
        }
    }

    @media (min-width: 640px) {
        .staffCard {
            .infoContainer {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    @media (min-width: 768px) {
        .staffCard {
            .infoContainer {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}

.addButton {
    background: #252525;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;

    &:disabled {
        background: #ccc;
        cursor: not-allowed;
    }

    &:hover:not(:disabled) {
        background: #374151;
    }
}

.deleteButton {
    color: #FF6B6B;
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    font-size: 20px;

    &:disabled {
        color: #ccc;
        cursor: not-allowed;
    }

    &:hover:not(:disabled) {
        color: #FF4444;
    }
}
//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;
$colorDeFuente2: #5c5c5c;

.body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #00000000;
    padding: 40px 0;
    min-height: 70vh;
    width: 90%;
    margin: 0 auto;

    .detallesCont {
        width: 100%;
        max-width: 900px;

        h2 {
            font-weight: 800;
            font-size: 24px;

            @media (min-width: 768px) {
                font-size: 30px !important;
            }
        }

        .eventName {
            margin-bottom: 8px;
            font-size: 16px;
            font-weight: 600;

            @media (min-width: 768px) {
                font-size: 18px;
            }
        }

        .fondo {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 10px;
            background-color: #cccccc79;
            border-radius: 10px;
            font-size: 12px;
            text-align: left;
            font-weight: 400;
            color: $colorDeFuente2;

            h3 {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 4px;
                justify-self: center;
                font-weight: 600;
                font-size: 12px;
                cursor: pointer;
                user-select: none;

                @media (min-width: 768px) {
                    font-size: 16px;
                }
            }
        }

        @media(min-width: 768px){

            h3{
                font-size: 18px;
            }
            p{
                font-size: 16px;
            }
        }
    }

    .tipoDeTicketsCont {
        border-radius: 10px;    
        width: 100%;
        max-width: 900px;
        margin-top: 32px;

        h4 {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 8px;
        }

        .contTickets{
            width: 100%;
        }

        @media(min-width: 768px){

            h4{
                font-size: 18px;
            }
        }
    }

    .tipoTicketGeneral {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-shadow: 1px 0 10px #bbbbbb;
        background-color: white;
        width: 100%;
        margin: 20px auto 0;
        border-radius: 10px;
        margin-bottom: 0;
        min-height: 70px;
        max-width: 900px;
        border: none;
    
        .botonGenerar{
            display: flex;
            align-items: center;
            justify-content: center;
            
            a{
                background-color: #252525;
                color: white;
                box-shadow: 1px 0 3px #bbbbbb;
                font-size: 12px;
                padding: 8px;
                border-radius: 5px;
                margin-right: 8px;
                text-align: center;
                display: flex;
                align-items: center;
                gap: 5px;
                @media(min-width: 768px){
                    font-size: 14px;
                }
            }
        }
    
        input[type="number"] {
            width: 40px;
            height: 40px;
            border: 1px solid #99419d;
            border-radius: 5px;
            text-align: center !important;
            font-family: "Montserrat", sans-serif;
            color: #99419d;
            font-size: 22px;
            background-color: #ffffff00;
            margin: 5px;
            outline: none;
            appearance: none;
            line-height: normal;
            -moz-appearance: textfield;
            padding-inline: 0;
        }
    
        h4 {
            text-align: left;
            color: #252525;
            font-size: 16px;
            font-weight: 600;
            margin: 0;
        }
    
        small {
            text-align: left;
            color: #252525;
            width: 100%;
            font-size: 12px;
        }
    
        h5 {
            text-align: left;
            font-size: 14px;
            color: #252525;
            margin: 0;
            font-weight: 500;
            line-height: 1.2;
        }
    
        .btnCantidad {
            background-color: #ffffff00;
            border: 1px solid #99419d;
            border-radius: 5px;
            cursor: pointer;
            padding: 4px 8px;
            color: #99419d;
        }
    
        .detalleTipoTicket {
            padding: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: flex-start;
        }
    
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    
        @media (min-width: 768px) {
            input[type="number"] {
                width: 45px;
            }
    
            h4 {
                font-size: 18px;
            }
        }

        &.unavailableTicket {
            opacity: 0.95;
            background-color: #fafafa;
            position: relative;
            overflow: hidden;
            
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: repeating-linear-gradient(
                    45deg,
                    rgba(0, 0, 0, 0.02),
                    rgba(0, 0, 0, 0.02) 10px,
                    rgba(0, 0, 0, 0.01) 10px,
                    rgba(0, 0, 0, 0.01) 20px
                );
            }
        }

        .unavailableMessage {
            color: $colorDeFuente2;
            font-size: 11px;
            font-weight: 500;
            margin-top: 4px;
        }

        .unavailableLabel {
            display: flex;
            align-items: center;
            gap: 2px;
            color: #ff7871;
            font-size: 12px;
            font-weight: 600;
            padding: 8px;
            
            svg {
                font-size: 16px;
            }
            
            @media(min-width: 768px) {
                font-size: 14px;
                
                svg {
                    font-size: 18px;
                }
            }
        }
    }
}

.headerRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 8px;

    h2 {
        font-weight: 900;
        font-size: 20px;
        margin: 0;
    }
}

.redirectButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    background-color: $violeta;
    color: white;
    font-family: $tipografia;
    font-size: 10px;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    svg {
        display: none;
    }

    &:hover {
        background-color: $violetaOscuro;
    }

    @media (min-width: 768px) {
        font-size: 14px;

        svg {
            display: block;
        }
    }

}

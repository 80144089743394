$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;
$colorDeFuente2: #5c5c5c;


.body {
    width: 100%;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .titulo {
        font-weight: 800;
        line-height: 1.2;
        font-size: 24px;
        color: $colorDeFuente;
        padding-left: 10px;
        width: 90%;
        margin: 16px auto 8px;
        max-width: 1100px;

        @media (min-width: 768px) {
            font-size: 30px;
        }
    }

    .subtitulo {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 4px;
        color: #1b1b1b;
        width: 90%;
        padding-left: 10px;
        max-width: 1100px;

        span {
            font-weight: 400;
        }

        @media (min-width: 768px) {
            font-size: 18px;
        }
    }

    .fondoSub {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 10px;
        background-color: #cccccc79;
        border-radius: 15px;
        width: 90%;
        max-width: 1100px;
        margin-bottom: 16px;

        p {
            font-size: 12px;
            text-align: left;
            font-weight: 400;
            color: $colorDeFuente2;

            @media (min-width: 768px) {
                font-size: 14px;
            }
        }
    }

    .noTiene {
        width: 80%;
        color: #ff6060;
        text-align: center;
        margin: 10vh auto;
        font-size: 12px;

        @media(min-width: 768px) {
            font-size: 14px;
        }
    }

    .contMapa {
        width: 90%;
        max-width: 1100px;
        height: fit-content;
        padding: 16px;
        border: solid 1px #ccc;
        border-radius: 10px;
        margin: 0 auto;
    }

    .darDeBajaButton {
        width: 90%;
        max-width: 400px;
        margin-top: 32px;
    }

    .verRegistros{
        font-size: 12px;
        padding: 8px;
        border: solid 1px #1b1b1b;
        margin-top: 32px;
        border-radius: 5px;
        font-weight: 500;

        @media(min-width: 768px){
            font-size: 14px;
        }
    }
}

/* Modal */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent, .modalContentRegistros {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    @media (min-width: 768px) {
        max-width: 800px; /* Ajusta en desktop */
    }
}

.modalContentRegistros {
    @media (min-width: 768px) {
        max-width: 800px; /* Ajusta el tamaño del modal en desktop */
    }
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

.modalTitle {
    font-size: 18px;
    font-weight: bold;
}

.closeButton {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.modalBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0;
    width: 100%;

    b {
        width: 100%;
        margin: 0 auto;
        padding: 8px;
    }

    input {
        width: 100%;
        margin: 0 auto;
        font-size: 14px;
        padding: 8px;
        border: solid 1px #ccc;
        border-radius: 8px;
    }
}

.modalFooter {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    gap: 10px;
}

/* Table styles */
.tableContainer {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: auto;
    width: 100%; /* Ocupa todo el ancho disponible */
}

.table {
    border-collapse: collapse;
    width: 100%;
    min-width: 600px; /* Asegura que la tabla sea más ancha que la pantalla en móviles */
    display: block; /* Importante para asegurar scroll horizontal en mobile */
    overflow-x: auto; /* Habilitar desplazamiento horizontal */
    margin-top: 10px;
    white-space: nowrap; /* Impide que se ajusten las celdas en una sola línea */
}

.table th,
.table td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
    word-wrap: break-word; /* Permite que las palabras largas se dividan correctamente */
    white-space: normal; /* Permite que las celdas se expandan en múltiples líneas */
    font-size: 14px;

    @media (max-width: 768px) {
        font-size: 12px;
        padding: 6px;
    }
}

.table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.bodyTable {
    width: 100%;
}

.errorRegistro {
    color: #ff6060;
    font-size: 12px;
    margin: 16px auto;
    width: 80%;
}

@media (max-width: 768px) {
    .modalContentRegistros {
        width: 95%; 
        padding: 10px;
    }

    .tableContainer {
        white-space: nowrap;
        overflow-x: auto; /* Asegura que puedas hacer scroll de lado a lado */
    }

    .modalBody {
        padding: 15px 0;
    }

    .modalFooter {
        flex-direction: column-reverse;
        gap: 8px;
    }
}
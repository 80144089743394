// DetallePagoExito.module.scss
$colorExito: #47bd8f;
$violeta: rgb(154, 66, 158);
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;
$colorFondo: #f7f7f7;

.exitoBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $colorFondo;
  font-family: $tipografia;
  color: $colorDeFuente;
  width: 90%;
  max-width: 900px;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  min-height: 70vh;

  .bodyDetalle {
    text-align: center;
    max-width: 100%;

    h1 {
      color: $colorDeFuente;
      text-align: left;
      font-size: 20px;
      margin-bottom: 4px;
      width: 100%;
      display: flex;
      align-items: center;
    }

    .fondoSub {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 10px;
      background-color: #cccccc79;
      border-radius: 15px;
      margin-bottom: 16px;
    }

    .aclaracionExito {
      font-size: 14px;
      color: $colorDeFuente;
      text-align: left;
    }

    .botonVolver{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #862f8b;
      font-size: 12px;
      color: #fff;
      border-radius: 5px;
      padding: 10px 20px;
      text-decoration: none;
      font-weight: bold;
      margin-top: 20px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
      width: auto !important;
      max-width: 200px;
      margin: 0 auto;

      &:hover {
        background-color: darken($violetaOscuro, 10%);
      }

      @media(min-width: 768px){
        font-size: 14px;
        padding: 10px 20px;
      }
    }

    .tablaDetallePago {
      margin: 20px 0;

      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
        border-radius: 15px;

        th {
          background-color: $violeta;
          color: #fff;
          border-radius: 10px 10px 0 0;
        }
        td{
          max-width: 60%;
          word-break: break-all;
        }

        th,
        td {
          padding: 10px;
          text-align: center;
          font-size: 14px;

          @media(min-width:768px) {
            font-size: 16px;
          }
        }

        th,
        td {
          &:first-child {
            &:last-child {
              border-radius: 10px 10px 0 0;
            }
          }
        }

        tr:last-child {
          td:first-child {
            border-bottom-left-radius: 10px;
          }
          td:last-child {
            border-bottom-right-radius: 10px;
          }
        }

        td {
          text-align: left;
          padding: 10px 15px;
          border-left: solid 1px #ccc;
          border-bottom: 1px solid #ccc;

          &.resultado {
            font-weight: bold;
            text-align: center;
            border-right: solid 1px #ccc;
            border-left: none;
          }
        }
      }
    }

    .botonVerTickets {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #862f8b !important;
      font-size: 12px;
      color: #fff;
      border-radius: 5px;
      padding: 10px 20px;
      text-decoration: none;
      font-weight: bold;
      margin-top: 20px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
      width: auto !important;
      max-width: 200px;
      margin: 0 auto;

      &:hover {
        background-color: darken($violetaOscuro, 10%);
      }

      @media(min-width: 768px){
        font-size: 14px;
        padding: 10px 20px;
      }
    }
  }

  @media(min-width: 768px){
    .bodyDetalle {
    
    h1{
      font-size: 24px;
    }
    .aclaracionExito {
      font-size: 16px;
    }

    }
  }
}

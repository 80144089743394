.staffCard {
    background: #fff;
    border-radius: 24px;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    border: 1px solid #f0f0f0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 639px) {
        padding: 16px;
        gap: 12px;
        border-radius: 16px;
    }

    .staffHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .userInfo {
            display: flex;
            align-items: center;
            gap: 12px;

            .userIcon {
                color: #99419D;
                font-size: 24px;

                @media (max-width: 639px) {
                    font-size: 20px;
                }
            }

            h3 {
                font-size: 20px;
                font-weight: 500;
                color: #333;

                @media (max-width: 639px) {
                    font-size: 16px;
                }
            }
        }
    }

    .infoContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        
        @media (min-width: 640px) {
            gap: 24px;
        }
        
        .infoRow {
            display: flex;
            flex-direction: column;
            gap: 4px;
            flex: 1;
            min-width: 200px;

            .label {
                color: #6B7280;
                font-size: 14px;
                font-weight: 500;
            }

            .valueWithCopy {
                display: flex;
                align-items: center;
                gap: 8px;
                background: #F9FAFB;
                padding: 8px;
                border-radius: 12px;

                .value {
                    flex: 1;
                    color: #111827;
                    font-size: 16px;
                }
            }

            .passwordContainer {
                display: flex;
                align-items: center;
                gap: 8px;
            }

            .passwordField {
                flex: 1;
                display: flex;
                align-items: center;
                gap: 8px;
                background: #F9FAFB;
                border-radius: 12px;
                padding: 8px;

                input {
                    background: none;
                    border: none;
                    font-size: 16px;
                    color: #111827;
                    width: 100%;
                    
                    &:focus {
                        outline: none;
                    }
                }

                button {
                    background: none;
                    border: none;
                    color: #6B7280;
                    cursor: pointer;
                    padding: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        color: #374151;
                    }
                }

                .copyButton {
                    margin-left: -4px;
                }
            }
        }
        .idRow {
            max-width: 100px !important;
            .value{
                height: auto !important;
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
            @media (min-width: 640px) {
                flex: 0 0 80px;
                min-width: auto;
                
                .value{
                    height: 40px !important;
                }
            }
        }
    }

    @media (min-width: 640px) {
        .infoContainer {
            .infoRow:not(.idRow) {
                flex-basis: calc(50% - 12px - 40px);
            }
        }
    }

    @media (min-width: 768px) {
        .infoContainer {
            .infoRow:not(.idRow) {
                flex-basis: calc(50% - 16px - 40px);
            }
            gap: 32px;
        }
    }
}

.copyButton {
    background: none;
    border: none;
    color: #6B7280;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;

    &:hover {
        color: #374151;
    }

    .copied {
        color: #10B981;
    }
}

.addButton {
    background: #252525;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;

    &:disabled {
        background: #ccc;
        cursor: not-allowed;
    }

    &:hover:not(:disabled) {
        background: #374151;
    }
}

.deleteButton {
    color: #FF6B6B;
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    font-size: 20px;

    &:disabled {
        color: #ccc;
        cursor: not-allowed;
    }

    &:hover:not(:disabled) {
        color: #FF4444;
    }
}

.reportButton {
    background-color: #252525;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    transition: opacity 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &:hover {
        opacity: 0.8;
    }

    .reportIcon {
        font-size: 20px;
    }

    @media (min-width: 768px) {
        font-size: 16px;
    }
} 
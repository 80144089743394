.container {
    padding: 40px 0;
    width: 90%;
    margin: 0 auto;
    min-height: 80vh;
    background-color: #fff;
    max-width: 800px;

    .header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 32px;

        h1 {
            font-size: 24px;
            font-weight: 600;
            color: #333;
            line-height: 1.4;
            display: flex;
            align-items: center;
            gap: 8px;

            span {
                font-weight: 400;
                color: #666;
                font-size: 16px;
                display: block;
                margin-top: 8px;
            }
        }
    }

    .rrppNombre {
        font-size: 16px;
        font-weight: 600;
        color: #333;
        padding: 8px 16px;
        background-color: #F3F4F6;
        border-radius: 16px;
        margin: 8px 0;
    }

    .statsContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 16px;
        margin-bottom: 32px;

        .statCard {
            background: #fff;
            border-radius: 16px;
            padding: 24px;
            box-shadow: 0 2px 8px rgba(0,0,0,0.1);
            border: 1px solid #f0f0f0;
            display: flex;
            align-items: center;
            gap: 16px;

            .statIcon {
                font-size: 32px;
                color: #99419D;
            }

            .statInfo {
                h3 {
                    font-size: 14px;
                    color: #666;
                    margin-bottom: 4px;
                }

                .statValue {
                    font-size: 24px;
                    font-weight: 600;
                    color: #333;
                }
            }
        }
    }

    .sectionTitle {
        font-size: 18px;
        font-weight: 600;
        color: #333;
        margin: 24px 0 16px;
        padding-bottom: 8px;
        border-bottom: 1px solid #eee;
    }

    .ticketList {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .ticketCard {
            background: #fff;
            border-radius: 16px;
            padding: 24px;
            box-shadow: 0 2px 8px rgba(0,0,0,0.1);
            border: 1px solid #f0f0f0;

            .ticketHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 16px;

                .ticketInfo {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    .ticketIcon {
                        color: #99419D;
                        font-size: 24px;
                    }

                    h3 {
                        font-size: 18px;
                        font-weight: 500;
                        color: #333;
                    }
                }

                .cantidad {
                    background: #F3F4F6;
                    padding: 6px 12px;
                    border-radius: 20px;
                    font-size: 14px;
                    color: #666;
                }
            }

            .ticketDetails {
                display: grid;
                gap: 12px;

                .detailRow {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 8px 0;
                    border-bottom: 1px solid #f0f0f0;

                    &:last-child {
                        border-bottom: none;
                    }

                    .label {
                        color: #666;
                        font-size: 14px;
                    }

                    .value {
                        font-weight: 500;
                        color: #333;
                    }
                }
            }
        }
    }

    @media (max-width: 639px) {
        .statsContainer {
            grid-template-columns: 1fr;
        }

        .ticketCard {
            padding: 16px;

            .ticketHeader {
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
            }
        }
    }
} 
.container {
    padding: 40px 0;
    min-height: 100vh;
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    max-width: 800px;

    .header {
        margin-bottom: 32px;

        h1 {
            font-size: 24px;
            font-weight: 800;
            color: #333;
            line-height: 1.4;

            span {
                font-weight: 400;
                color: #666;
                font-size: 14px;
                display: block;
                margin-top: 8px;

                @media (min-width: 768px) {
                    font-size: 16px;
                }
            }

            @media (min-width: 768px) {
                font-size: 30px;
            }
        }
    }

    .sectionTitle {
        font-size: 18px;
        font-weight: 600;
        color: #333;
        margin: 24px 0 16px;
        padding-bottom: 8px;
        border-bottom: 1px solid #eee;
    }

    .staffList {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .addStaffSection {
        margin-top: 32px;

        .addStaffForm {
            background: #fff;
            border-radius: 12px;
            padding: 20px;
            box-shadow: 0 1px 3px rgba(0,0,0,0.1);
            border: 1px solid #eee;
            display: flex;
            gap: 16px;
            align-items: center;
            flex-wrap: wrap;

            .inputGroup {
                display: flex;
                align-items: center;
                gap: 8px;
                flex: 1;
                min-width: 200px;

                svg {
                    color: #666;
                }

                input {
                    flex: 1;
                    padding: 8px;
                    border: 1px solid #ddd;
                    border-radius: 6px;
                    font-size: 14px;

                    &:focus {
                        outline: none;
                        border-color: #666;
                    }
                }
            }

            .addButton {
                background: #333;
                color: white;
                border: none;
                padding: 8px 16px;
                border-radius: 6px;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 8px;
                font-size: 14px;

                &:hover {
                    background: #444;
                }

                &:disabled {
                    background: #ccc;
                    cursor: not-allowed;
                }
            }
        }
    }

    .noStaff {
        text-align: center;
        color: #666;
        padding: 32px;
        background: #f8f8f8;
        border-radius: 12px;
    }

    @media (max-width: 768px) {
        .addStaffForm {
            flex-direction: column;
            
            .inputGroup {
                width: 100%;
            }

            .addButton {
                width: 100%;
                justify-content: center;
            }
        }
    }
} 
//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;
$colorDeFuente2: #5c5c5c;

.bodyCrearEvento {
  width: 100%;
  color: $colorDeFuente;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    color: $colorDeFuente;
    font-weight: 700;
    margin-top: 40px;
    text-align: left;
    width: 100%;
    font-size: 24px;
    margin-left: 4px;
  }

  .subtitulo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    background-color: #cccccc79;
    color: #1b1b1b;
    border-radius: 15px;
    max-width: 900px;
    margin-bottom: 16px;
    font-size: 14px;
    width: 100%;
  }

  .error {
    color: red;
    text-align: left;
    font-size: 14px!important;
    margin-bottom: 5px;
    margin-top: -3px;
  }

  .lineaDivisora {
    border-top: 1px solid #cdcdcd;
    box-sizing: border-box;
    width: 90%;
    height: 1px;
    margin: 20px auto;
    /* Centra el separador */
  }

  .contForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-width: 900px;
    height: auto;

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      label {
        font-size: 18px;
        color: $colorDeFuente2;
        width: 100%;
        text-align: left;
        font-weight: 600;
        margin-bottom: 5px;
      }

      textarea{
        border: solid 1px #ccc;
        border-radius: 5px;
        margin-bottom: 10px;
        font-size: 14px;
        min-height: 100px;
        padding: 0 5px;
      }

      small {
        font-weight: 500;
        text-align: left;
        width: 100%;
      }

      .checkboxLabel{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
      }
      
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        min-width: 90%;

        input {
          height: 42px;
          width: 100%;
          border-radius: 8px;
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid #ccc;
          font-size: 14px;
        }

        p {
          height: auto;
          line-height: 16px;
          font-size: 16px;
          width: 98%;

          input {
            height: 16px;
          }
        }

        .imgEvento{
          width: 90%;
          max-width: 400px;
          border-radius: 10px;
        }

        .subtituloImg{
          margin: 16px 0 8px;
          font-weight: 600;
          width: 100%;

        }

        input[type="checkbox"] {
          width: 16px;
        }

        input[type="file"] {
          height: auto;
        }

        textarea {
          width: 100%;
        }
      }

      .subtitulo2 {
        font-size: 18px;
        color: #5c5c5c;
        width: 100%;
        text-align: left;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .mayoresCont{
        margin: 20px 0 5px 0 !important;


        label{
          margin: 0!important;
          width: auto!important;
        }
      }

      .containerCheckbox {
        width: 100%;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 15px;
        width: 95%;

        label {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          text-align: left;
        }

        input {
          display: none;
        }

        p {
          margin-left: 5px;
          font-size: 14px;
          width: auto !important;
          margin-bottom: 0;
        }

        svg {
          overflow: visible;
          color: #252525;
          height: 14px;
          width: 14px;
          margin-left: 2px;
        }

        .path {
          fill: none;
          stroke: #252525;
          stroke-width: 4;
          /* Ancho de trazo ajustado */
          stroke-linecap: round;
          stroke-linejoin: round;
          transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
          stroke-dasharray: 241 9999999;
          stroke-dashoffset: 0;
        }

        input:checked ~ svg .path {
          stroke-dasharray: 70.5096664428711 9999999;
          stroke-dashoffset: -262.2723388671875;
        }

        span {
          margin-left: 0.5em;
          font-size: 14px;
          /* Tamaño de fuente para el texto */
        }
      }

      .tipoDeTicket {
        width: 100%;
        border: solid 1px #ccc;
        border-radius: 15px;
        margin-bottom: 10px;
        padding: 8px;
        position: relative;

        label {
          font-size: 16px;
        }

        .eliminarBtn {
          font-size: 14px;
          border: none;
          font-weight: 500;
          cursor: pointer;
          outline: none;
          transition: all 0.3s ease;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 8px 16px;
          min-width: 250px;
          border-radius: 8px;
          margin: 10px;
          background-color: rgba(0, 0, 0, 0);
          color: #99419d;

          &:hover {
            border: solid 1px #99419d;
          }

          svg {
            margin-left: 4px;
          }
        }

        .numeroTipoTicket {
          position: absolute;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          padding: 4px;
          font-weight: 500;
          height: 40px;
          color: $violeta;
        }

        .nombreLabel {
          margin-top: 45px;
        }
      }

      .agregarBtn {
        font-size: 14px;
        border: none;
        font-weight: 500;
        cursor: pointer;
        outline: none;
        transition: all 0.3s ease;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 8px 16px;
        min-width: 250px;
        border-radius: 8px;
        margin: 10px;
        background-color: rgba(0, 0, 0, 0);
        color: #99419d;

        &:hover {
          border: solid 1px #99419d;
        }

        svg {
          margin-left: 4px;
        }
      }

      .BtCrearEvento {
        background-color: $violeta;
        min-height: 40px;
        min-width: 116px;
        color: #ffffff;
        font-weight: 600;
        margin: 0 10px 10px;
        border: none;
        width: 90%;
        border-radius: 8px;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        outline: none;
        transition: background-color 0.3s;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        right: 0;

        :hover {
          background-color: $violetaOscuro;
        }
      }
    }
  }

  .borderValid {
    border: 2px solid #47BD8F;
  }
  
  .borderInvalid {
    border: 2px solid #FF5C5D;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;

    .contForm {
      width: 80%;
      margin-right: 10%;
      padding: 30px;

      h2 {
        margin-top: 0;
      }
    }

    .numeroTipoTicket {
      font-size: 24px;
    }

    .nombreLabel {
      margin-top: 30px;
    }
  }
}

.eventoFoto {
  display: none;

  @media (min-width: 768px) {
    display: block;
    width: 10%;
    height: 100%;
    border-radius: 15px 0 0 15px;

    img {
      width: 100px;
      overflow: hidden;
      background-size: cover;
      margin-top: 30px;
      margin-left: 20px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

.btWhatsapp {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 60px;
  width: 60px;
  background-color: #25d366;
  border-radius: 100%;
  font-size: 40px;
  color: #fff;
  position: fixed;
  bottom: 40px;
  right: 40px;
  transition: all 0.5s;

  :hover {
    color: #fff;
    transform: scale(1.1);
    transition: all 0.5s;
  }
}

.checkboxApple {
  position: relative;
  width: 50px!important;
  height: 25px;
  margin: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input[type="checkbox"] {
    display: none;
  }
}

.labelToggle {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px!important;
  height: 25px;
  border-radius: 50px;
  background: linear-gradient(to bottom, #b3b3b3, #e6e6e6);
  cursor: pointer;
  transition: all 0.3s ease;
}

.labelToggle:after {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.checkboxApple input[type="checkbox"]:checked + label {
  background: linear-gradient(to bottom, #4cd964, #5de24e);
}

.checkboxApple input[type="checkbox"]:checked + label:after {
  transform: translateX(25px);
}

.checkboxApple label:hover {
  background: linear-gradient(to bottom, #b3b3b3, #e6e6e6);
}

.checkboxApple label:hover:after {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.yep {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 25px;
}

.ubiActual{
  width: 100%;
  text-align: left;
  margin: 16px 0;
  color: $colorDeFuente2;
}

.ubiNueva{
  width: 100%;
  text-align: left;
  margin: 16px 0 8px;
  color: $colorDeFuente2;
}

.mercadoPagoSection {
  margin: 20px 0;
  padding: 20px;
  border-radius: 15px;
  border: 1px solid #ccc;
  width: 100%;

  .mpVinculado {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 20px;
    background-color: #e8f5e9;
    border-radius: 10px;

    .checkIcon {
      width: 40px;
      height: 40px;
      object-fit: contain;
      color: #2e7d32;
    }

    .mpStatus {

      h4, h3, p {
        text-align: center;
      }

      svg{
        color: #2e7d32;
      }

      h4 {
        color: #2e7d32;
        margin: 0 0 5px 0;
        font-size: 18px;
        font-weight: 600;
      }

      p {
        margin: 0;
        color: #1b5e20;
        font-size: 14px;
      }
    }
  }

  .mpNoVinculado {
    text-align: center;
    padding: 20px;

    h4, h3, p {
      text-align: center;
    }

    .mpLogo {
      width: 200px;
      height: auto;
      margin-bottom: 20px;
    }

    h4 {
      color: #333;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 600;
    }

    h3 {
      font-size: 11px;
      color: #666;
      margin-bottom: 25px;
      font-weight: normal;
      line-height: 1.5;
    }

    .botonConectarMp {
      display: inline-flex;
      align-items: center;
      gap: 10px;
      background-color: #009ee3;
      color: white;
      padding: 12px 24px;
      border-radius: 5px;
      text-decoration: none;
      font-weight: 500;
      margin-bottom: 15px;
      transition: all 0.2s ease;
      border: none;
      font-family: "Montserrat", Helvetica, Arial, sans-serif;
      font-size: 16px;
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

      .mpButtonLogo {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }

      

      &:hover {
        background-color: #008acc;
        transform: translateY(-1px);
      }
    }

    .mpSecurityNote {
      font-size: 12px;
      color: #666;
      margin-top: 10px;
    }

    p {
      color: #666;
      font-size: 12px;
      margin-top: 10px;
    }
  }

  @media (min-width: 768px) {
    .mpNoVinculado {
      .mpLogo {
        width: 250px;
      }

      h4 {
        font-size: 24px;
      }

      h3 {
        font-size: 18px;
      }
    }
  }
}
//Colores de la empresa
$violeta: rgb(154, 66, 158);
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

.bodyInforme{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #f7f7f7;
    width: 100%;
    min-height: 70vh;
    
}
.body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #f7f7f7;
    width: 100%;
    padding: 40px 0;

    .headerContainer {
        width: 90%;
        max-width: 900px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    .titleContainer {
        flex: 1;
    }

    .InformeVentasH1 {
        text-align: left;
        font-size: 24px;
        font-weight: 800;
        color: #1b1b1b;
        padding: 0 0 16px;
        margin-bottom: 8px;

        span {
            font-size: 20px;
            font-weight: 400;
            line-height: 90%;

            @media (min-width: 768px) {
                font-size: 24px;
            }
        }

        @media (min-width: 768px) {
            font-size: 30px;
        }
    }

    .brtopleft{
        border-radius: 5px 0 0 0!important;
    }
    .brtopright{
        border-radius: 0 5px 0 0!important;
    }
    .brbottomright{
        border-radius: 0 0 5px 0!important;
    }
    .brbottomleft{
        border-radius: 0 0 0 5px!important;
    }

    .seccionCards{
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-around;
        width: 90%;
        max-width: 900px;
        margin-bottom: 22px;

        .cardVentas{
            width: 30%;
            color: #1b1b1b;
            border-radius: 15px;
            box-shadow: 1px 0 20px #bbbbbb;
            padding: 8px;

            h5{
                font-weight: 500;
                font-size: 10px;
            }
            p{
                margin: 8px 0;
                font-size: 12px;
                word-break: break-all;
                font-weight: 600;
            }

            @media(min-width: 768px){
                padding: 16px;
                h5{
                    font-size: 12px;
                }
                p{
                    font-size: 16px;
                }
            }
        }

        .cardVentasTotales{
            background-color: #252525 ;
            color: #f7f7f7;
        }
        .cardVentaALiquidar{
            background-color:#d1ffd1;
        }
        .cardVentaComisiones{
            background-color: #f5daff;
        }
    }

    .aclaracion{
        font-size: 10px;
        width: 80%;
        max-width: 800px;  
        text-align: center; 
        
        @media(min-width: 768px){
            font-size: 12px;
        }
    }

    .seccionDeInforme{
        width: 90%;
        max-width: 900px;

        h2 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            font-size: 18px;
            text-align: left;
            margin-top: 32px;
            font-weight: 700;
            width: 100%;
            border-bottom: solid 1px #ccc;
            margin-bottom: 8px;
            display: flex;
            gap: 50px;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            border-radius: 10px;
            box-shadow: 1px 0 20px #bbbbbb;

            th{
                text-align: left;
                background-color: $violetaOscuro;
                color: #fff;
                padding: 4px;
                font-size: 14px;
                font-weight: 400;
            }
            td{
                text-align: left;
                font-weight: 500;
                font-size: 12px;
                background-color: #fff;
                padding: 4px;
                border-left: solid 1px #ccc;

                &:first-child {
                    border-left: none !important;
                }

                @media(min-width: 768px) {
                    font-size: 14px;
                }
            }
            b{
                font-weight: 600;
            }
    
            .totalRow {
                border-radius: 0 0 10px 10px !important;
                border-top: 1px solid #ccc;
                border-left: none !important;

                td {
                    background-color: white;
                    color: #1b1b1b;
                    padding: 6px;
                    font-weight: 500;
                    border-left: none !important;

                    b {
                        font-weight: 700;                        
                    }

                    &:last-child {
                        border-radius: 0 0 10px 0 !important;
                    }
                }

                .totalLabel {
                    text-align: right;
                    border-radius: 0 0 10px 10px !important;
                    padding-right: 8px;
                }

                td:last-child {
                    text-align: left;
                }
            }
        }
    }

    .pdfButton {
        border: none;
        border-radius: 15px;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s ease;
        margin-left: 20px;

        &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(0,0,0,0.2);
        }
    }

    .pdfIcon {
        font-size: 24px;
        color: #808080;
    }
}

.tablaRrpps {
    margin-top: 2rem;
    padding: 1rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);

    h3 {
        color: #862f8b;
        margin-bottom: 1rem;
        font-size: 1.2rem;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 1rem;

        th, td {
            padding: 0.75rem;
            text-align: left;
            border-bottom: 1px solid #e4e4e4;
        }

        th {
            background-color: #f8f8f8;
            color: #862f8b;
            font-weight: 600;
        }

        tr:hover {
            background-color: #f5f5f5;
        }

        td {
            color: #333;
        }
    }
}

// Estilos responsivos
@media (max-width: 768px) {
    .tablaRrpps {
        overflow-x: auto;
        
        table {
            min-width: 600px;
        }
    }
}
.container {
    padding: 40px 0;
    width: 90%;
    margin: 0 auto;
    min-height: 80vh;
    background-color: #fff;
    max-width: 800px;
    margin: 0 auto;

    .header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 32px;

        h1 {
            font-size: 24px;
            font-weight: 800;
            color: #333;
            line-height: 1.4;

            span {
                font-weight: 400;
                color: #666;
                font-size: 14px;
                display: block;
                margin-top: 8px;

                @media (min-width: 768px) {
                    font-size: 16px;
                }
            }

            @media (min-width: 768px) {
                font-size: 30px;
            }
        }
    }

    .sectionTitle {
        font-size: 18px;
        font-weight: 600;
        color: #333;
        margin: 24px 0 16px;
        padding-bottom: 8px;
        border-bottom: 1px solid #eee;
    }

    .staffList {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .noStaff {
        text-align: center;
        color: #666;
        padding: 32px;
        background: #f8f8f8;
        border-radius: 12px;

        @media (max-width: 639px) {
            padding: 24px;
            font-size: 14px;
        }
    }

    @media (min-width: 640px) {
        .staffCard {
            .infoContainer {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    @media (min-width: 768px) {
        .staffCard {
            .infoContainer {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
} 
.eventoCreadoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0 auto;
  background: linear-gradient(135deg, #f5f7fa 0%, #ffffff 100%);

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    width: 90%;
    max-width: 700px;
    margin: 0 auto;
  }

  .successIcon {
    color: #00c853;
    font-size: 5rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0 4px 8px rgba(0, 200, 83, 0.2));
  }

  .content {
    background: white;
    padding: 2.5rem;
    border-radius: 24px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    max-width: 600px;
    width: 100%;
    text-align: center;

    h2 {
      color: #333;
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 2rem;
      background: linear-gradient(135deg, #65126A 0%, #99419d 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .mpContainer {
      margin: 2rem 0;
      padding: 1.5rem;
      background: #f8f9fa;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      .mpLogo {
        width: 180px;
        height: auto;
        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
      }

      p {
        color: #444;
        font-size: 1.1rem;
        margin: 0;
      }
    }

    .infoCard {
      background: rgba(153, 65, 157, 0.05);
      border-left: 4px solid #99419d;
      padding: 1.5rem;
      border-radius: 12px;
      display: flex;
      align-items: flex-start;
      gap: 1rem;
      margin: 2rem 0;
      text-align: left;

      .infoIcon {
        color: #99419d;
        font-size: 1.5rem;
        flex-shrink: 0;
      }

      p {
        color: #555;
        margin: 0;
        line-height: 1.5;
      }
    }

    .contactSection {
      margin-top: 2rem;
      padding-top: 2rem;
      border-top: 1px solid #eee;

      h3 {
        color: #333;
        font-size: 1.3rem;
        margin-bottom: 1rem;
      }

      .contactLinks {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        a {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          color: #65126A;
          text-decoration: none;
          font-size: 1.1rem;
          padding: 0.5rem;
          border-radius: 8px;
          transition: all 0.3s ease;

          &:hover {
            background: rgba(153, 65, 157, 0.1);
            transform: translateX(5px);
          }

          svg {
            font-size: 1.3rem;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 1rem;

    .content {
      padding: 1.5rem;

      h2 {
        font-size: 1.5rem;
      }

      .mpContainer {
        padding: 1rem;

        .mpLogo {
          width: 140px;
        }
      }

      .contactLinks {
        a {
          font-size: 1rem;
        }
      }
    }
  }
}

.mpVinculadoSeccion img {
  width: 100px;
}

.mpVinculadoSeccion h2 {
  color: #009ee3;
  font-weight: 700;
}

.evento-img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.fondoDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px;
  background-color: rgba(204, 204, 204, 0.4745098039);
  border-radius: 15px;
  margin-bottom: 8px;
}

.contact-info {
  font-size: 18px;
  margin-top: 20px;
  color: #333333;
  display: flex;
  align-items: center;
}
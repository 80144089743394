//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;
$colorDeFuente2: #5c5c5c;

.body {
    width: 100%;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .titulo {
        font-weight: 800;
        line-height: 1.2;
        font-size: 24px;
        color: $colorDeFuente;
        padding-left: 10px;
        width: 90%;
        margin: 16px auto 8px;
        max-width: 900px;

        @media (min-width: 768px) {
            font-size: 30px;
        }
    }

    .subtitulo {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 4px;
        color: #1b1b1b;
        width: 90%;
        padding-left: 10px;
        max-width: 900px;

        span {
            font-weight: 400;
        }

        @media (min-width: 768px) {
            font-size: 16px;
        }
    }

    .fondoSub {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 10px;
        background-color: #cccccc79;
        border-radius: 15px;
        width: 90%;
        max-width: 900px;
        margin-bottom: 16px;

        p {
            font-size: 14px;
            text-align: left;
            font-weight: 400;
            color: $colorDeFuente2;
        }
    }

    .tiposDeTickets {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        max-width: 900px;

        .tipoDeTicket {
            width: 100%;
            border: solid 1px #ccc;
            border-radius: 10px;
            margin-bottom: 10px;
            padding: 8px;
            position: relative;

            label {
                font-size: 16px;
            }

            .nombreLabel{
                width: 100%;
                margin-top: 16px;
            }

            input {
                height: 42px;
                width: 100%;
                border-radius: 8px;
                padding: 10px;
                margin-bottom: 10px;
                border: 1px solid #ccc;
                font-size: 14px;
            }

            .eliminarBtn {
                font-size: 12px;
                border: solid 1px rgb(255, 92, 93);
                font-weight: 500;
                align-self: center !important;
                cursor: pointer;
                outline: none;
                transition: all 0.3s ease;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px 16px;
                border-radius: 8px;
                margin: 10px auto;
                background-color: rgba(0, 0, 0, 0);
                color: rgb(255, 92, 93);

                &:hover {
                    background-color: rgb(255, 92, 93);
                    color: white;
                }

                svg {
                    margin-left: 4px;
                }
            }

            .error{
                color: rgb(255, 92, 93);
                font-size: 12px;
                text-align: center;
                border-radius: 15px;
                padding: 0px;
                margin-top: -5px;
              }

            .numeroTipoTicket {
                display: inline;
                width: 100%;
                font-size: 18px;
                padding: 4px;
                font-weight: 600;
                color: $violeta;
                padding-bottom: 16px;

                span{
                    font-weight: 400;
                }
                .nombreTipoDeTicket{
                    display: inline;
                }
                
            }
        }
    }

    .contBtnGuardar {
        width: 90%;
        max-width: 900px;
        margin-top: 30px;
    }

    .checkboxApple {
        position: relative;
        width: 50px !important;
        height: 25px;
        margin: 10px 20px 20px 4px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input[type="checkbox"] {
            display: none;
        }
    }

    .labelToggle {
        position: absolute;
        top: 0;
        left: 0;
        width: 50px !important;
        height: 25px;
        border-radius: 50px;
        background: linear-gradient(to bottom, #b3b3b3, #e6e6e6);
        cursor: pointer;
        transition: all 0.3s ease;
    }

    .labelToggle:after {
        content: '';
        position: absolute;
        top: 1px;
        left: 1px;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        transition: all 0.3s ease;
    }

    .checkboxApple input[type="checkbox"]:checked+label {
        background: linear-gradient(to bottom, #4cd964, #5de24e);
    }

    .checkboxApple input[type="checkbox"]:checked+label:after {
        transform: translateX(25px);
    }

    .checkboxApple label:hover {
        background: linear-gradient(to bottom, #b3b3b3, #e6e6e6);
    }

    .checkboxApple label:hover:after {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    }
    
    .agregarBtn {
        font-size: 14px;
        border: solid 1px #99419d;
        font-weight: 500;
        cursor: pointer;
        outline: none;
        transition: all 0.3s ease;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 8px 16px;
        min-width: 250px;
        border-radius: 8px;
        margin: 10px;
        background-color: rgba(0, 0, 0, 0);
        color: #99419d;

        &:hover {
            border: solid 1px #99419d;
        }

        svg {
            margin-left: 4px;
        }
    }

}
// Variables
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;
$colorDeFuente2: #5c5c5c;

.body {
    background: linear-gradient(100deg, #E380E9 0.71%, #65126A 97.21%);
    width: 100%;
    min-height: 100vh;
    padding: 32px 2.5%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
        width: 100%;
        max-width: 1600px; // Aumentado de 1200px
        display: flex;
        flex-direction: row;
        height: 30px;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 24px;

        .volver {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;
            color: #fff;
            padding: 0 8px;
            margin: -10px 4px 0;
            font-size: 14px;
            background: none;
            border: none;
            cursor: pointer;

            @media(min-width: 768px) {
                font-size: 18px;
            }
        }
    }
}

.crearMenuContainer {
    width: 100%;
    max-width: 1600px; 
    margin: 0 auto;
    padding: 0 0 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $tipografia;

    h2 {
        color: #fff !important;
        font-size: 24px !important;
        font-weight: 800;
        line-height: 1.2;
        margin: 0 0 16px;
        text-align: center;
        width: 100%;

        @media (min-width: 768px) {
            font-size: 32px !important;
        }
    }

    .fondoDescripcion {
        width: 100%;
        background-color: hsla(0,0%,80%,.475);
        border-radius: 15px;
        padding: 16px;
        margin-bottom: 32px;
        text-align: center;

        p {
            color: #fff;
            font-size: 15px;
            font-weight: 400;
            margin: 0;
            line-height: 1.5;
        }
    }

    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .productos {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        justify-content: center;
        margin-bottom: 40px;

        @media (min-width: 768px) {
            gap: 24px;
        }
    }

    .producto {
        width: calc(50% - 6px);
        min-width: 160px;
        max-width: none;
        min-height: 380px;
        padding: 16px 16px 45px;

        @media (min-width: 768px) {
            width: calc(25% - 18px);
            min-width: 280px;
            max-width: 320px;
            min-height: auto;
        }

        background: #fff;
        border: 1px solid #ddd;
        border-radius: 12px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        transition: transform 0.2s ease;
        position: relative;

        &:hover {
            transform: translateY(-2px);
        }

        label {
            font-size: 14px;
            color: $colorDeFuente2;
            margin-bottom: 8px;
            width: 100%;
            text-align: left;
        }

        .productImage {
            width: 100%;
            background-color: #f8f8f8;
            border-radius: 8px;
            margin-bottom: 12px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                aspect-ratio: 1 / 1;
                object-fit: cover;
                border-radius: 8px;
                border: solid 1px #ccc;
            }

            .editIcon {
                position: absolute;
                top: 10px;
                right: 5px;
                z-index: 10;
                filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.5));
                color: #fff;
                cursor: pointer;
                transition: all 0.2s linear;

                &:hover {
                    color: #eeeeee;
                }
            }
        }

        .inputProducto {
            width: 100%;
            height: 40px;
            border-radius: 8px;
            padding: 8px;
            margin-bottom: 12px;
            border: 1px solid #ccc;
            font-size: 14px;

            &:focus {
                outline: none;
                border-color: $violeta;
                box-shadow: 0 0 0 2px rgba($violeta, 0.2);
            }
        }

        .deleteBtn {
            position: absolute;
            bottom: 16px;
            right: 16px;
            background: transparent;
            border: 1px solid rgb(255, 92, 93);
            border-radius: 8px;
            width: auto;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 8px 12px;
            gap: 8px;
            color: rgb(255, 92, 93);
            font-size: 14px;
            transition: all 0.3s ease;
            opacity: 1;

            @media (max-width: 768px) {
                padding: 6px 10px;
                font-size: 13px;
                gap: 6px;
                bottom: 10px;
                right: 10px;
                
                svg {
                    width: 14px;
                    height: 14px;
                }
            }

            &:hover {
                background: rgb(255, 92, 93);
                color: white;
                transform: translateY(-2px);
            }

            svg {
                width: 16px;
                height: 16px;
            }

            &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }

    .agregarBtn {
        width: calc(25% - 18px); // Cambiado de 33.33% para mostrar 4 productos por fila
        min-width: 280px;
        max-width: 320px;
        height: auto;
        aspect-ratio: 1;
        font-size: 16px;
        border: 2px solid #fff;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 24px;
        border-radius: 12px;
        background-color: transparent;
        color: #fff;
        transition: all 0.3s ease;
        align-items: center;
        justify-content: center;

        &:hover:not(:disabled) {
            background-color: $violeta;
            border-color: #ccc;
            transform: translateY(-2px);
        }

        svg {
            width: 50px;
            height: 50px;
        }

        @media(max-width: 768px) {
            width: 100%;
            aspect-ratio: auto;
            height: auto;
            padding: 16px;
            flex-direction: row;
            justify-content: center;
        }
    }

    .submitButton {
        background-color: $violeta;
        color: #fff;
        margin-top: 16px;
        width: 100%;
        max-width: 320px;
        padding: 16px 32px;
        font-size: 16px;
        font-weight: 600;
        border-radius: 12px;
        
        &:hover:not(:disabled) {
            background-color: $violetaOscuro;
            transform: translateY(-2px);
        }

        &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
        }
    }
}

// Media queries para responsividad
@media (max-width: 1200px) {
    .crearMenuContainer .producto {
        width: calc(50% - 12px);
    }
}

@media (max-width: 768px) {
    .crearMenuContainer {
        .producto {
            width: calc(50% - 12px);
            min-width: 200px;
            max-width: none;
            padding: 16px 16px 45px;

            .productImage {
                margin-bottom: 10px;
            }

            label {
                font-size: 13px;
                margin-bottom: 6px;
            }

            .inputProducto {
                height: 36px;
                padding: 6px 10px;
                font-size: 13px;
                margin-bottom: 10px;
            }
        }

        h2 {
            font-size: 20px;
        }

        .fondoDescripcion p {
            font-size: 14px;
        }
    }
}

.productosExistentes {
    width: 100%;
    margin-bottom: 32px;

    h3 {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 24px;
        text-align: center;
    }
}

.listaProductos {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;

    @media (min-width: 768px) {
        gap: 24px;
    }
}

.productoExistente {
    width: calc(50% - 6px);
    min-width: 160px;
    max-width: none;
    min-height: 340px;
    padding: 16px 16px 45px;

    @media (min-width: 768px) {
        width: calc(25% - 18px);
        min-width: 280px;
        max-width: 320px;
        min-height: auto;
    }

    background: #fff;
    border: 1px solid #ddd;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    position: relative;

    @media (max-width: 768px) {
        min-width: 140px;
        
        img {
            margin-bottom: 8px;
        }

        .detallesProducto {
            h4 {
                font-size: 14px;
                margin: 0 0 4px 0;
            }

            p {
                font-size: 12px;
                margin: 2px 0;
            }
        }

        .deleteBtn {
            padding: 6px 10px;
            bottom: 12px;
            right: 12px;
            font-size: 13px;
            
            svg {
                width: 14px;
                height: 14px;
            }
        }
    }

    &:hover {
        transform: translateY(-2px);
    }

    img {
        width: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border-radius: 8px;
        border: solid 1px #ccc;
        margin-bottom: 12px;
    }

    .detallesProducto {
        h4 {
            font-size: 16px;
            color: $colorDeFuente;
            margin: 0 0 8px 0;
        }

        p {
            font-size: 14px;
            color: $colorDeFuente2;
            margin: 4px 0;
        }
    }

    .deleteBtn {
        position: absolute;
        bottom: 16px;
        right: 16px;
        background: transparent;
        border: 1px solid rgb(255, 92, 93);
        border-radius: 8px;
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 8px 12px;
        gap: 8px;
        color: rgb(255, 92, 93);
        font-size: 14px;
        transition: all 0.3s ease;
        opacity: 1;

        @media (max-width: 768px) {
            padding: 6px 10px;
            font-size: 13px;
            gap: 6px;
            bottom: 10px;
            right: 10px;
            
            svg {
                width: 14px;
                height: 14px;
            }
        }

        &:hover {
            background: rgb(255, 92, 93);
            color: white;
            transform: translateY(-2px);
        }

        svg {
            width: 16px;
            height: 16px;
        }

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}

// Media queries para responsividad
@media (max-width: 1200px) {
    .productoExistente {
        width: calc(50% - 12px);
    }
}

@media (max-width: 768px) {
    .productoExistente {
        width: calc(50% - 12px);
        max-width: none;
    }
}

.productActions {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.productControls {
    display: flex;
    align-items: center;
    gap: 0,5rem;
}

.habilitarControl {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    
    span {
        margin-right: 0;
        font-size: 14px;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #99419d;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.nombreProducto {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;
    width: 100%;

    @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
    }
}

.inputNombre {
    width: 100%;
    padding: 6px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
    
    &:focus {
        outline: none;
        border-color: $violeta;
        box-shadow: 0 0 0 2px rgba($violeta, 0.2);
    }
}

.guardarBtn {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-size: 0.9em;
    transition: all 0.2s ease;
    
    @media (min-width: 768px) {
        width: auto;
    }
    
    &:hover {
        background-color: darken(#4CAF50, 10%);
        transform: translateY(-1px);
    }
    
    svg {
        width: 16px;
        height: 16px;
    }
}

.editImageBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    transition: background-color 0.3s;

    &:hover {
        background-color: rgba(0, 0, 0, 0.9);
    }
}

.productImage {
    position: relative;
}

.checkboxApple {
    position: relative;
    width: 50px !important;
    height: 25px;
    margin: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input[type="checkbox"] {
        display: none;
    }
}

.labelToggle {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px !important;
    height: 25px;
    border-radius: 50px;
    background: linear-gradient(to bottom, #b3b3b3, #e6e6e6);
    cursor: pointer;
    transition: all 0.3s ease;

    &:after {
        content: '';
        position: absolute;
        top: 1px;
        left: 1px;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        transition: all 0.3s ease;
    }
}

.checkboxApple input[type="checkbox"]:checked + label {
    background: linear-gradient(to bottom, #4cd964, #5de24e);
}

.checkboxApple input[type="checkbox"]:checked + label:after {
    transform: translateX(25px);
}

.checkboxApple label:hover {
    background: linear-gradient(to bottom, #b3b3b3, #e6e6e6);
}

.checkboxApple label:hover:after {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.yep {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 25px;
}
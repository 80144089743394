//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contenedorEventos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 70vh;
    padding-bottom: 50px;

    @media(min-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
}

.mpVinculadoContainer, .mpConectarContainer {
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 16px;
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.mpVinculadoContainer {
  border: 1px solid #00C853;
  background: linear-gradient(to right, rgba(0, 200, 83, 0.05), rgba(0, 200, 83, 0.02));

  .mpStatusHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-bottom: 8px;

    .mpLogo {
      height: 32px;
      object-fit: contain;
    }
  }

  .mpStatusText {
    color: #00C853;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
  }
}

.mpConectarContainer {
  border: 1px solid #009ee3;
  background: linear-gradient(to right, rgba(0, 158, 227, 0.05), rgba(0, 158, 227, 0.02));
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  .mpConectarText {
    color: #333;
    font-size: 0.9rem;
    text-align: center;
    font-weight: 500;
  }

  .mpButton {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #009ee3;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 8px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
    text-decoration: none;
    box-shadow: 0 2px 4px rgba(0, 158, 227, 0.2);
    transition: all 0.3s ease;

    &:hover {
      background-color: darken(#009ee3, 5%);
      box-shadow: 0 4px 8px rgba(0, 158, 227, 0.3);
    }
  }

  .mpSecurityNote {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #666;
    font-size: 0.8rem;
    
    svg {
      color: #009ee3;
    }
  }
}

.logo {
    width: 100px;
    margin: 30px;
    margin-bottom: 10px;
    animation: expandirYGirar 1.5s infinite linear;
    box-shadow: 0px 0px 20px white;
    border-radius: 50%;

    @keyframes expandirYGirar {
        0% {
            transform: rotate(0deg);
        }

        50% {
            transform: rotate(180deg);
        }

        100% {
            transform: rotate(360deg);
        }

    }
}

.text {
    font-size: 18px;
    font-weight: 400;
    color: #ccc;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
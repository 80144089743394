.contenedorConsumiciones {
    width: 100%;
    padding-bottom: 0.1px;
  }
  
  .consumisiones {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    border: solid 1px #ccc !important;
    width: 95%;
    border-radius: 10px;
    min-height: 70px;
    max-width: 800px;
    border: none;
    margin: 16px auto;
  
    &:last-child {
      margin-bottom: 16px;
    }
  
    .resultadoCantidad {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-self: center;
      padding: 8px;
    }
  
    input[type="number"] {
      width: 40px;
      height: 40px;
      border: 1px solid #99419d;
      border-radius: 5px;
      text-align: center !important;
      font-family: "Montserrat", sans-serif;
      color: #99419d;
      font-size: 22px;
      background-color: #ffffff00;
      margin: 5px;
      outline: none;
      appearance: none;
      line-height: normal;
      -moz-appearance: textfield;
      padding-inline: 0;
    }
  
    h4 {
      text-align: left;
      color: #252525;
      font-size: 14px;
      font-weight: 600;
      margin: 0;
    }
  
    small {
      text-align: left;
      color: #252525;
      width: 100%;
      font-size: 12px;
    }
  
    h5 {
      text-align: left;
      font-size: 14px;
      color: #252525;
      margin: 0;
      font-weight: 500;
      line-height: 1.2;
    }
  
    .btnCantidad {
      background-color: #ffffff00;
      border: 1px solid #99419d;
      border-radius: 5px;
      cursor: pointer;
      padding: 8px 6px;
      color: #99419d;
    }
  
    .detalleTipoTicket {
      padding: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
    }
  
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  
    @media (min-width: 768px) {
      input[type="number"] {
        width: 45px;
      }
  
      h4 {
        font-size: 18px;
      }
    }
  }
  
  .verMasBtn {
    align-self: center;
    background-color: #00000000;
    color: #99419d;
    height: 40px;
    border: solid 1px #99419d;
    border-radius: 10px;
    text-decoration: none;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    margin: 0 10% 15px;
    width: 80%;
  
    &:hover {
      background-color: #862f8b;
      color: white;
      transform: scale(1.1);
    }
  }
  
  .consumision {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  
    h5 {
      padding: 0 0 0 8px;
      font-size: 12px;
    }
  
    h4 {
      padding: 0 0 0 8px;
      word-break: break-word;
      font-size: 14px;
    }
  }
  
  .cantidadMenu {
    width: 30px;
    height: 35px;
  }
  
  .imgConsumision {
    width: 80px;
    min-width: 80px;
    height: 80px;
    margin: 0;
    border: solid 1px #ccc;
    border-radius: 8px;
    overflow: hidden;
    object-fit: cover;
  }
  
  @media (min-width: 768px) {
    .consumision h4 {
      padding: 8px;
      font-size: 16px;
    }
  
    .consumision h5 {
      font-size: 14px;
    }
  }
.infoGoogle {
    width: 100%;
    padding: 2rem 0;
    background-color: #fff;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .comments {
    text-align: center;
  }
  
  .comment {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    font-weight: bold;
  }
  
  .imageContainer {
    display: inline-flex;
    overflow: hidden;
    position: relative;
    width: 100%;
    white-space: nowrap;
  }
  
  .image {
    width: 120px;
    height: 80px;
    object-fit: contain;
    margin: 0 2rem;
  }
  
  .marqueeGroup {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    animation: scroll 20s linear infinite;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .imageContainer:hover .marqueeGroup {
    animation-play-state: paused;
  }
.container {
    padding: 24px;
    min-height: 80vh;
    background-color: #fff;
    max-width: 800px;
    margin: 0 auto;

    .header {
        margin-bottom: 32px;

        h1 {
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 12px;
            color: #1a1a1a;
        }

        p {
            font-size: 16px;
            color: #666;
            line-height: 1.5;
        }
    }

    .cardContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .card {
        display: flex;
        align-items: center;
        padding: 24px;
        background: #fff;
        border-radius: 12px;
        text-decoration: none;
        color: inherit;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease;
        border: 1px solid #eee;

        &:hover {
            background-color: #f8f8f8;
        }

        .iconWrapper {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 16px;
            flex-shrink: 0;

            .icon {
                width: 24px;
                height: 24px;
                color: #99419d;
            }
        }

        .content {
            flex: 1;

            h2 {
                font-size: 16px;
                font-weight: 600;
                color: #1a1a1a;
                margin-bottom: 4px;
            }

            p {
                font-size: 14px;
                color: #666;
                margin: 0;
            }
        }

        .arrowIcon {
            width: 24px;
            height: 24px;
            color: #999;
            margin-left: 16px;
        }
    }

    @media (min-width: 768px) {
        padding: 32px;

        .header {
            h1 {
                font-size: 32px;
            }
        }

        .card {
            padding: 28px;

            .iconWrapper {
                width: 48px;
                height: 48px;

                .icon {
                    width: 28px;
                    height: 28px;
                }
            }

            .content {
                h2 {
                    font-size: 18px;
                }

                p {
                    font-size: 15px;
                }
            }
        }
    }
} 